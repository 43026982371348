import axios from "@/libs/axios";

let base = '';

// 用户
export const login = (param) => {
    return axios.request({
        url: `${base}/api/user/login`,
        params: param,
        method: 'get'
    });
}
export const logout = () => {
    return axios.request({
        url: `${base}/api/user/logout`,
        method: 'get'
    });
}
export const getCurrentUser = () => {
    return axios.request({
        url: `${base}/api/user/current`,
        method: 'get'
    });
}
export const lookPwd = (param) => {
    return axios.request({
        url: `${base}/api/user/lookPwd`,
        method: 'get',
        params: param
    });
}
export const modifyPwd = (param) => {
    return axios.request({
        url: `${base}/api/user/modifyPwd`,
        method: 'post',
        data: param
    });
}
export const getUserList = (param) => {
    return axios.request({
        url: `${base}/api/user/list`,
        method: 'post',
        data: param
    });
}
export const getUserDetail = (id) => {
    return axios.request({
        url: `${base}/api/user/` + id,
        method: 'get'
    });
}
export const saveUser = (param) => {
    return axios.request({
        url: `${base}/api/user/save`,
        method: 'post',
        data: param
    });
}
export const deleteUser = (id) => {
    return axios.request({
        url: `${base}/api/user/del/` + id,
        method: 'get'
    });
}
export const resetPwd = (param) => {
    return axios.request({
        url: `${base}/api/user/resetPwd`,
        method: 'post',
        data: param
    });
}

// 代理商
export const getAgentList = (param) => {
    return axios.request({
        url: `${base}/api/agent/list`,
        method: 'post',
        params: param
    });
}
export const getAgentDetail = (id) => {
    return axios.request({
        url: `${base}/api/agent/` + id,
        method: 'get'
    });
}
export const saveAgent = (param) => {
    return axios.request({
        url: `${base}/api/agent/save`,
        method: 'post',
        data: param
    });
}
export const deleteAgent = (id) => {
    return axios.request({
        url: `${base}/api/agent/del/` + id,
        method: 'get'
    });
}

// 客户
export const getCustomerList = (param) => {
    return axios.request({
        url: `${base}/api/customer/list`,
        method: 'post',
        params: param
    });
}
export const getCustomerDetail = (id) => {
    return axios.request({
        url: `${base}/api/customer/` + id,
        method: 'get'
    });
}
export const saveCustomer = (param) => {
    return axios.request({
        url: `${base}/api/customer/save`,
        method: 'post',
        data: param
    });
}
export const deleteCustomer = (id) => {
    return axios.request({
        url: `${base}/api/customer/del/` + id,
        method: 'get'
    });
}

// 设备
export const getDeviceList = (param) => {
    return axios.request({
        url: `${base}/api/device/list`,
        method: 'post',
        params: param
    });
}
export const getDeviceDetail = (id) => {
    return axios.request({
        url: `${base}/api/device/` + id,
        method: 'get'
    });
}
export const saveDevice = (param) => {
    return axios.request({
        url: `${base}/api/device/save`,
        method: 'post',
        data: param
    });
}
export const deleteDevice = (id) => {
    return axios.request({
        url: `${base}/api/device/del/` + id,
        method: 'get'
    });
}
export const sendChemicalsCmd = () => {
    return axios.request({
        url: `${base}/api/chemicals/sendCmd`,
        method: 'get'
    });
}
export const sendLockCmd = (id) => {
    return axios.request({
        url: `${base}/api/device/lock/` + id,
        method: 'get'
    });
}
export const getFactoryCmd = (id) => {
    return axios.request({
        url: `${base}/api/device/factory/` + id,
        method: 'get'
    });
}
export const getDevicePrograms = (id) => {
    return axios.request({
        url: `${base}/api/device/program/` + id,
        method: 'get'
    });
}
export const getDeviceMachineNos = (id) => {
    return axios.request({
        url: `${base}/api/report/device/` + id + '/machineNo',
        method: 'get'
    });
}


// 上报数据
export const getReportDataList = (param) => {
    return axios.request({
        url: `${base}/api/report/list`,
        method: 'post',
        data: param
    });
}
export const getChemicalsList = (param) => {
    return axios.request({
        url: `${base}/api/chemicals/list`,
        method: 'get',
        params: param
    });
}
export const cleanReportData = (param) => {
    return axios.request({
        url: `${base}/api/report/clean`,
        method: 'post',
        data: param
    });
}


// 分析
export const countDevice = (param) => {
    return axios.request({
        url: `${base}/api/analy/count/device`,
        method: 'get',
        params: param
    });
}
export const countReport = (param) => {
    return axios.request({
        url: `${base}/api/analy/report`,
        method: 'get',
        params: param
    });
}
export const countDevice4Map = () => {
    return axios.request({
        url: `${base}/api/analy/count/device/map`,
        method: 'get'
    });
}
export const countUser = () => {
    return axios.request({
        url: `${base}/api/analy/count/user`,
        method: 'get'
    });
}
export const countCustomerChemical = () => {
    return axios.request({
        url: `${base}/api/analy/count/chemical/customer`,
        method: 'get'
    });
}
export const countWeight = () => {
    return axios.request({
        url: `${base}/api/analy/count/weight`,
        method: 'get'
    });
}
export const countChemicals = (param) => {
    return axios.request({
        url: `${base}/api/analy/count/chemicals`,
        method: 'get',
        params: param
    });
}
export const getWeather = () => {
    return axios.request({
        url: `${base}/api/weather/now`,
        method: 'get'
    });
}


// 地区
export const getAreaList = (param) => {
    return axios.request({
        url: `${base}/api/area/list`,
        method: 'get',
        params: param
    });
}
export const getAreaTree = () => {
    return axios.request({
        url: `${base}/api/area/tree`,
        method: 'get'
    });
}


// 通信
export const getRecordList = (param) => {
    return axios.request({
        url: `${base}/api/cmd/list`,
        method: 'get',
        params: param
    });
}
export const getRecordActionList = (param) => {
    return axios.request({
        url: `${base}/api/cmd/list/action`,
        method: 'get',
        params: param
    });
}